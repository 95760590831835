import React, { useCallback, useState } from 'react';
import { Logo } from '@/assets/icons';
import Link from 'next/link';
import {
  DesktopMenu,
  MobileMenu,
  MenuIcon,
} from '@/components/shared/Header/component';

export default function Header() {
  const [showMenu, setShowMenu] = useState(false);

  const handleShowMenu = useCallback(() => setShowMenu(!showMenu), [showMenu]);

  return (
    <div className="border-neutral-grayLight sticky inset-x-0 top-0 z-40 border-b-[0.5px] bg-white">
      <div className="container relative flex h-[64px] items-center justify-start md:h-20 lg:justify-between">
        <MenuIcon
          className="absolute right-4 top-6 block md:top-8 lg:hidden"
          handleShowMenu={handleShowMenu}
          showMenu={showMenu}
        />
        <Link href="/" aria-label="Logo website" aria-hidden="true">
          <Logo className="absolute top-3 h-12  md:top-5 lg:h-12" />
        </Link>
        <DesktopMenu />
        {showMenu && <MobileMenu handleShowMenu={handleShowMenu} />}
      </div>
    </div>
  );
}
